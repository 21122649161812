import request from '@/utils/requestV2'
const qs = require('qs')

// 获取私有文件的签名url
export function signurlByAttach (data) {
  return request({
    url: '/ooh-resource/oss/endpoint/signurl-by-attach',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除文件和附件表数据
export function removeFileAttach (data) {
  return request({
    url: '/ooh-resource/oss/endpoint/remove-file-attach',
    method: 'post',
    data: qs.stringify(data)
  })
}
