<template>
  <div>
    <Modal v-model="visible" :width="1000">
      <template #header>
        <p>配置资产租赁金额</p>
      </template>
      <Row type="flex" :gutter="8" class="mb-3 mt-1">
        <i-col span="24">
          <div class="mb-2">
            <strong>选择资产</strong>
          </div>
          <div>
            <RadioGroup v-model="ChkResourceId">
              <Radio
                v-for="item in resourceArray"
                :key="item.id"
                :label="item.id"
                >{{ item.innerCode }}({{ item.signArea }}㎡)</Radio
              >
            </RadioGroup>
          </div>
        </i-col>
        <i-col span="24">
          <div class="mb-2">
            <strong>租金策略</strong>
          </div>
          <Row :gutter="8" class="mb-2">
            <i-col span="4">
              <label class="mr-2 text-gray-400 leading-6">签约面积</label>
              <Input v-model="strategy.area" type="number" number>
                <template #append>
                  <span>㎡</span>
                </template>
              </Input>
            </i-col>
            <i-col span="4">
              <label class="mr-2 text-gray-400 leading-6">起始单价</label>
              <Input v-model="strategy.signPrice" type="number" number>
                <template #append>
                  <span>元/㎡·⽉</span>
                </template>
              </Input>
            </i-col>
            <i-col span="4">
              <label class="mr-2 text-gray-400 leading-6">递增周期</label>
              <Input v-model="strategy.month" type="number" number>
                <template #append>
                  <span>⽉</span>
                </template>
              </Input>
            </i-col>
            <i-col span="4">
              <label class="mr-2 text-gray-400 leading-6">递增类型</label>
              <i-select v-model="strategy.upType" class="w-full">
                <Option :value="1"> 百分比 </Option>
                <Option :value="2"> 固定金额 </Option>
              </i-select>
            </i-col>
            <i-col span="4">
              <label class="mr-2 text-gray-400 leading-6">递增幅度</label>
              <Input v-model="strategy.upValue" type="number" number>
                <template #append>
                  <span>{{ strategy.upType === 1 ? "%" : "元" }}</span>
                </template>
              </Input>
            </i-col>
            <i-col span="4">
              <div class="mr-2 text-gray-400 leading-6" style="width: 100%">
                &nbsp;
              </div>
              <i-button
                class="mr-1 bg-green-500 border-green-500 hover:bg-green-600 hover:border-green-600"
                type="primary"
                @click="confirmGenerate"
              >
                生成租金方案
              </i-button>
            </i-col>
          </Row>
        </i-col>
        <i-col span="24">
          <div style="margin: 2px">
            <Icon
              type="ios-alert-outline"
              style="font-size: 14px; color: #ef4444"
            />
            表示时间区间可能出现冲突或者超出合同约定范围
          </div>

          <Row
            :gutter="8"
            style="
              text-align: center;
              line-height: 35px;
              background: rgba(34, 54, 88);
            "
          >
            <i-col span="4">开始时间</i-col>
            <i-col span="4">结束时间</i-col>
            <i-col span="4">签约面积</i-col>
            <i-col span="4">租金单价（元/㎡·⽉ ）</i-col>
            <i-col span="4">月租金（元）</i-col>
            <i-col span="4">操作</i-col>
          </Row>
          <Row
            :gutter="8"
            style="text-align: center; line-height: 35px"
            v-for="(item, index) in rentSetInfo"
            :key="index"
          >
            <i-col span="4">
              <DatePicker
                v-model="item.startDate"
                type="date"
                transfer
                :clearable="false"
                placeholder="开始日期"
                class="w-full"
                @on-change="
                  () => {
                    changeStartDate(item);
                  }
                "
              />
            </i-col>
            <i-col span="4">
              <DatePicker
                v-model="item.endDate"
                :clearable="false"
                type="date"
                transfer
                placeholder="结束日期"
                class="w-full"
                @on-change="
                  () => {
                    changeEndDate(item);
                  }
                "
              >
              </DatePicker>
            </i-col>
            <i-col span="4">
              <Input
                v-model="item.area"
                type="number"
                number
                @on-change="changeSignArea(item)"
              >
                <template #append>
                  <span>㎡</span>
                </template>
              </Input>
            </i-col>
            <i-col span="4">
              <Input
                v-model="item.signPrice"
                type="number"
                number
                @on-change="changeSignPrice(item)"
              >
                <template #append>
                  <span>元/㎡·⽉</span>
                </template>
              </Input>
            </i-col>
            <i-col span="4">
              <Input
                v-model="item.monthPrice"
                type="number"
                number
                @on-change="changeMonthPrice(item)"
              >
                <template #append>
                  <span>⽉</span>
                </template>
              </Input>
            </i-col>
            <i-col span="4">
              <a style="margin-right: 5px; color: red" @click="deleteRow(item)"
                >删除</a
              >
            </i-col>
          </Row>

          <!-- <Table :columns="rentSetting" stripe size="small" :data="rentSetInfo">

          </Table> -->
        </i-col>
      </Row>
      <template #footer>
        <i-button
          type="primary"
          class="bg-green-500 border-green-500 hover:bg-green-600 hover:border-green-600"
          @click="confirmRentSet()"
        >
          确认修改
        </i-button>
      </template>
    </Modal>
  </div>
</template>
<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
import { ParseDate, GetDateStr } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'
/** new api */
import { listResourceFee, createDeviceFee, saveOrUpdateResourceFeeList } from '@/api/contract/contract'
export default {
  components: {},
  props: {},
  data () {
    return {
      visible: false,

      strategy: {
        area: 0,
        signPrice: 0,
        month: 12,
        upType: 1,
        upValue: 0,
        contractId: 0,
        crIds: ''
      },
      totalSignArea: 0,
      rentSetInfo: [],

      resourceArray: [],
      ChkResourceId: 0

    }
  },
  mounted () {},
  created () {
    // this.initlistResourceAndFee()
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    showModal (contractResouces, defaultResourceId) {
      let totalArea = 0
      this.resourceArray = []
      contractResouces.forEach((item) => {
        totalArea += parseFloat(item.signArea.toFixed(2))
      })
      this.resourceArray.push({
        id: -99,
        innerCode: '全部资产',
        signArea: parseFloat(totalArea.toFixed(2))
      })

      this.resourceArray = this.resourceArray.concat(contractResouces)
      this.ChkResourceId = defaultResourceId
      this.visible = true
    },
    // 初始化租金方案
    initResourceFee () {
      const query = {
        contractId: this.contractInfo.id,
        crId: this.ChkResourceId
      }
      listResourceFee(query).then((res) => {
        if (res.length) {
          this.strategy.signPrice = res[0].signPrice
          this.strategy.upType = res[0].upType
          this.strategy.upValue = res[0].upValue
          this.rentSetInfo = res
        } else {
          this.rentSetInfo = [
            {
              id: new Date().getTime(),
              startDate: this.contractInfo.startDate,
              endDate: this.contractInfo.endDate,
              area: this.totalSignArea,
              signPrice: 0,
              monthPrice: 0,
              startDateWarning: false,
              endDateWarning: false
            }
          ]
        }
      })
    },
    // 确认生成租金方案
    confirmGenerate () {
      this.strategy.contractId = this.contractInfo.id
      this.strategy.crId = this.ChkResourceId
      createDeviceFee(this.strategy).then((res) => {
        this.initResourceFee()
      })
    },
    changeStartDate (record) {
      this.rentSetInfo.forEach((element) => {
        if (element.id === record.id) {
          element.startDate = ParseDate(record.startDate)
        }
      })
      this.calcRentList()
    },
    changeEndDate (record) {
      this.rentSetInfo.forEach((element) => {
        if (element.id === record.id) {
          const newEnd = ParseDate(record.endDate)
          // element.endDate = "";
          element.endDate = newEnd
        }
      })
      this.calcRentList()
    },
    changeSignArea (record) {
      this.rentSetInfo.forEach((element) => {
        if (element.id === record.id) {
          element.area = record.area
          element.monthPrice = (element.signPrice * element.area).toFixed(2)
        }
      })
    },
    changeSignPrice (record) {
      this.rentSetInfo.forEach((element) => {
        if (element.id === record.id) {
          element.signPrice = record.signPrice
          if (element.monthPrice === 0) {
            element.monthPrice = (element.signPrice * element.area).toFixed(2)
          }
        }
      })
    },

    changeMonthPrice (record) {
      this.rentSetInfo.forEach((element) => {
        if (element.id === record.id) {
          element.monthPrice = record.monthPrice
          if (element.signPrice === 0) {
            element.signPrice = (element.monthPrice / element.area).toFixed(2)
          }
        }
      })
    },
    deleteRow (record) {
      this.$Modal.confirm({
        title: '删除确认',
        content:
          '删除的时间区间会自动合并至相邻的条目内，是否删除当前时间区间的配置信息？',
        onOk: () => {
          if (this.rentSetInfo.length < 2) {
            this.$Notice.error({ desc: '最后一条数据，不支持删除操作！' })
          } else {
            this.rentSetInfo.forEach((item, index) => {
              if (item.id === record.id) {
                if (!index) { // 删除的第一条
                  this.rentSetInfo[index + 1].startDate = ParseDate(item.startDate)
                } else { // 删除的不是第一条
                  this.rentSetInfo[index - 1].endDate = ParseDate(item.endDate)
                }
                this.rentSetInfo.splice(index, 1)
              }
            })
            // this.calcRentList()
          }
        }
      })
    },
    calcRentList () {
      const calcAfterData = []
      const calcBeforeData = this.rentSetInfo.sort((a, b) => {
        return (
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        )
      })

      calcBeforeData.forEach((item, index) => {
        let newItem = {
          id: 0
        }
        item.startDateWarning = false
        item.endDateWarning = false
        if (index === 0) {
          if (
            new Date(item.startDate).getTime() >
            new Date(this.contractInfo.startDate).getTime()
          ) {
            const newItem = {
              id: new Date().getTime(),
              startDate: this.contractInfo.startDate,
              endDate: GetDateStr(item.startDate, -1),
              signPrice: item.signPrice,
              area: item.area,
              monthPrice: item.monthPrice,
              startDateWarning: false,
              endDateWarning: false
            }
            calcAfterData.push(newItem)
          }
        } else if (index !== calcBeforeData.length - 1) {
        // 只要当前item不是最后一条数据
          if (
            new Date(GetDateStr(item.endDate, 1)).getTime() <
            new Date(calcBeforeData[index + 1].startDate).getTime()
          ) {
            newItem = {
              id: new Date().getTime(),
              startDate: GetDateStr(item.endDate, 1),
              endDate: GetDateStr(calcBeforeData[index + 1].startDate, -1),
              signPrice: item.signPrice,
              monthPrice: item.monthPrice,
              area: item.area,
              startDateWarning: false,
              endDateWarning: false
            }
          }
        } else {
          // 最后一条数据时，判断结束时间是否到达合同结束时间
          if (
            new Date(item.endDate).getTime() <
            new Date(this.contractInfo.endDate).getTime()
          ) {
            // 当最后一条数据结束时间都小于合同结束时间
            newItem = {
              id: new Date().getTime(),
              startDate: GetDateStr(item.endDate, 1),
              endDate: this.contractInfo.endDate,
              signPrice: item.signPrice,
              monthPrice: item.monthPrice,
              area: item.area,
              startDateWarning: false,
              endDateWarning: false
            }
          }
        }
        calcAfterData.push(item)
        if (newItem.id) {
          calcAfterData.push(newItem)
        }
      })
      calcAfterData.forEach((item, index) => {
        // 日期警告验证
        // 结束日期小于开始日期，给予警告
        if (new Date(item.startDate).getTime() > new Date(item.endDate).getTime()) {
          item.startDateWarning = true
          item.endDateWarning = true
        } else if (
          new Date(item.startDate).getTime() <
          new Date(this.contractInfo.startDate).getTime()
        ) {
          item.startDateWarning = true
        } else if (
          new Date(item.endDate).getTime() >
          new Date(this.contractInfo.endDate).getTime()
        ) {
          item.endDateWarning = true
        } else if (
          new Date(item.startDate).getTime() >
          new Date(this.contractInfo.endDate).getTime()
        ) {
          item.startDate = true
        } else if (index < calcAfterData.length - 1) {
          if (
            new Date(GetDateStr(item.endDate, 1)).getTime() >
            new Date(calcAfterData[index + 1].startDate).getTime()
          ) {
            item.endDateWarning = true
            calcAfterData[index + 1].startDateWarning = true
          }
        }
      })
      this.rentSetInfo = calcAfterData
    },
    confirmRentSet () {
      this.$Modal.confirm({
        title: '操作确认',
        content:
          '确认保存当前租金策略？',
        onOk: () => {
          const query = {
            contractId: this.contractInfo.id,
            feeList: this.rentSetInfo.map(item => {
              item.startDate = ParseDate(item.startDate)
              item.endDate = ParseDate(item.endDate)
              return item
            })
          }
          if (this.ChkResourceId > 0) {
            query.crId = this.ChkResourceId
          }
          saveOrUpdateResourceFeeList(query).then((res) => {
            this.$store.commit('set_contract_update', new Date())
            this.visible = false
          })
        }
      })
    }
  },
  watch: {
    ChkResourceId (newVal, oldVal) {
      this.totalSignArea = 0
      this.resourceArray
        .filter((x) => newVal === x.id)
        .forEach((element) => {
          this.totalSignArea += parseFloat(element.signArea.toFixed(2))
        })
      this.strategy.area = parseFloat(this.totalSignArea.toFixed(2))
      this.initResourceFee()
    }
  },
  computed: {
    contractInfo () {
      return this.$store.state.investmentContract.contractInfo
    }
  }
}
</script>
